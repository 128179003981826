import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { Cloud } from '../../globalTypes';

type CloudsProps = {
  status: string,
  error: Error | undefined,
  data: Cloud[] | undefined,
}

function Clouds(props: CloudsProps) {

  const { data, status, error } = props;

  return (
    <>
      {status === "loading" ? (
        "Loading..."
      ) : error instanceof Error ? (
        <span>Error: {error.message}</span>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableCell align="right">Cloud Name</TableCell>
              <TableCell align="right">Cloud Description</TableCell>
              <TableCell align="right">Region</TableCell>
              <TableCell align="right">Cloud Provider</TableCell>
              <TableCell align="right">Distance</TableCell>
            </TableHead>
            <TableBody>
              {data?.map((cloud: Cloud) => (
                <TableRow key={cloud.cloud_name}>
                  <TableCell align="right">{cloud.cloud_name}</TableCell>
                  <TableCell align="right">{cloud.cloud_description}</TableCell>
                  <TableCell align="right">{cloud.geo_region}</TableCell>
                  <TableCell align="right">{cloud.cloud_provider}</TableCell>
                  <TableCell align="right">{cloud.distance?.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default Clouds;
