import React from 'react';
import axios from 'axios'
import {
  useQuery,
} from "react-query";

import Typography from '@material-ui/core/Typography';

import Clouds from './components/CloudList'
import ProviderSelector from './components/ProvidersSelector';
import RegionsSelector from './components/RegionsSelector';
import GeolocationInputs from './components/GeolocationData';

import { Cloud } from './globalTypes';

function useClouds() {
  return useQuery(
    "clouds",
    async (): Promise<Array<Cloud>> => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/clouds`
      );
      return data;
    }
  );
}

const calcDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  // taken from https://www.movable-type.co.uk/scripts/latlong.html
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI/180; // φ, λ in radians
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c; // in metres
}

function App() {
  const { status, data, error } = useClouds();
  const [cloudsFilteredList, setCloudsFilteredList] = React.useState<Cloud[]>([]);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [selectedRegions, setSelectedRegions] = React.useState<string[]>([]);
  const [geoLocation, setGeoLocation] = React.useState<number[]>([]);

  const filterClouds = () => {
    const inProviderList = (cloud: Cloud) =>
      selectedProviders.includes(cloud.cloud_provider) || selectedProviders.length === 0
    const inRegionList = (cloud: Cloud) =>
      selectedRegions.includes(cloud.geo_region) || selectedRegions.length === 0
    setCloudsFilteredList(
      (data as Cloud[] || [])
        .filter(inProviderList)
        .filter(inRegionList)
        .sort((cloud1: Cloud, cloud2: Cloud) => (cloud1.distance || 0) - (cloud2.distance || 0))
    );
  };

  React.useEffect(() => {
    filterClouds();
    return () => {
      setCloudsFilteredList(data as Cloud[]);
    }
  }, [data, selectedRegions, selectedProviders, geoLocation]);

  React.useEffect(() => {
    if (data?.length && geoLocation.length) {
      data.forEach((cloud: Cloud) => {
        cloud.distance = calcDistance(
          cloud.geo_latitude,
          cloud.geo_longitude,
          geoLocation[0],
          geoLocation[1],
        )
      })
      filterClouds();
    }
  }, [data, geoLocation])

  
  return (
    <div className="App">
      <Typography variant="h5">
        Select the clouds
      </Typography>
      <p>
        <ProviderSelector
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
        />
        <RegionsSelector
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
        />
        <GeolocationInputs
          geoLocation={geoLocation}
          setGeoLocation={setGeoLocation}
        />
      </p>
      <Clouds
        data={cloudsFilteredList}
        error={error as Error}
        status={status}
      />
    </div>
  );
}

export default App;
