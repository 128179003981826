import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
  },
}));

type SelectorProps = {
  data: string[] | undefined,
  onChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown; }>,
    child: React.ReactNode
  ) => void,
  value: string[],
  title: string,
}

const Selector = (props: SelectorProps) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="selector-label">{props.title}</InputLabel>
      <Select
        labelId="selector-label"
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input />}
      >
        {props.data?.map((element: string) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
        </Select>
      </FormControl>
  );
}

export default Selector;