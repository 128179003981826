import React from 'react';
import axios from 'axios';

import { useQuery } from "react-query";

import Selector from '../Selector';

function useRegions() {
  return useQuery(
    "regions",
    async (): Promise<Array<string>> => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/regions`
      );
      return data;
    }
  );
}

type RegionsSelectorProps = {
  selectedRegions: string[],
  setSelectedRegions: React.Dispatch<React.SetStateAction<string[]>>
}

function RegionsSelector(props: RegionsSelectorProps) {
  const { data } = useRegions();

  const handleSelect = (event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>) => {
    props.setSelectedRegions(event.target.value as string[]);
  }

  return (
    <Selector
      value={props.selectedRegions}
      onChange={handleSelect}
      data={data}
      title={"Select region"}
    />
  );
}

export default RegionsSelector;