import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 150,
  },
}));

type GeolocationProps = {
  setGeoLocation: React.Dispatch<React.SetStateAction<number[]>>,
  geoLocation: number[],
}

type GeoLocationResult = {
  coords: {
    latitude: number,
    longitude: number,
  },
}

function GeolocationInputs (props: GeolocationProps) {
  const classes = useStyles();

  navigator.geolocation.getCurrentPosition(
    (GeoLocation: GeoLocationResult) => {
      props.setGeoLocation(
        [
          GeoLocation.coords.latitude,
          GeoLocation.coords.longitude,
        ]);
    },
  );

  return (
    <>
      <FormControl className={classes.formControl}>
        <TextField
          label="Latitude"
          value={props.geoLocation[0]}
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Longitude"
          value={props.geoLocation[1]}
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
    </>
  );
}

export default GeolocationInputs;