import React from 'react';
import axios from 'axios';

import { useQuery } from "react-query";

import Selector from '../Selector';

function useRegions() {
  return useQuery(
    "providers",
    async (): Promise<Array<string>> => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/providers`
      );
      return data;
    }
  );
}

type ProviderSelectorProps = {
  selectedProviders: string[],
  setSelectedProviders: React.Dispatch<React.SetStateAction<string[]>>
}

function ProviderSelector(props: ProviderSelectorProps) {
  const { data } = useRegions();

  const handleSelect = (event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>) => {
    props.setSelectedProviders(event.target.value as string[]);
  }

  return (
    <Selector
      value={props.selectedProviders}
      onChange={handleSelect}
      data={data}
      title={"Select provider"}
    />
  );
}

export default ProviderSelector;